import { createTheme } from '@mui/material/styles'
import DefaultTheme from './DefaultTheme'

/**
 * Configure the Dark palette for the theme here.
 */
const DarkTheme = createTheme({
    ...DefaultTheme,
    palette: {
        mode: 'dark',
    },
})

export default DarkTheme
