import React, { useState } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppContext } from './context/AppContext'
import DarkTheme from './theme/DarkTheme'
import LightTheme from './theme/LightTheme'
import SSOApp from './DefaultLandingPage'

function AppWrapper() {
    const [isDarkMode, setDarkMode] = useState<boolean>(true)

    const appContext = {
        isDarkMode: isDarkMode,
        toggleDarkMode: () => {
            setDarkMode(!isDarkMode)
        },
    }

    return (
        <AppContext.Provider value={appContext}>
            <ThemeProvider
                theme={appContext.isDarkMode ? DarkTheme : LightTheme}
            >
                <CssBaseline />
                <SSOApp />
            </ThemeProvider>
        </AppContext.Provider>
    )
}

export default AppWrapper
