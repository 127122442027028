import { createTheme } from '@mui/material/styles'
import DefaultTheme from './DefaultTheme'

/**
 * Configure the Light palette for the theme here.
 */
const LightTheme = createTheme({
    ...DefaultTheme,
    palette: {
        mode: 'light',
    },
})

export default LightTheme
