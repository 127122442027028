import { createTheme } from '@mui/material/styles'

/**
 * Configure the Default palette for the theme here.
 */
const DefaultTheme = createTheme({
    typography: {
        fontFamily: ['basic-sans', 'sans-serif'].join(','),
    },
})

/**
 * Heading 1 Type customization
 */
DefaultTheme.typography.h1 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
    fontSize: '5rem',
    fontWeight: '700',
    letterSpacing: '0em',
    [DefaultTheme.breakpoints.down('md')]: {
        fontSize: '3rem',
        fontWeight: '700',
    },
}

DefaultTheme.typography.h2 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
}

DefaultTheme.typography.h3 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
}

DefaultTheme.typography.h4 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
}

DefaultTheme.typography.h5 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
}

DefaultTheme.typography.h6 = {
    fontFamily: ['greycliff-cf', 'sans-serif'].join(','),
}

export default DefaultTheme
