import React from 'react'
import {
    Box,
    Container,
    Link,
    Modal,
    Typography,
    useTheme,
} from '@mui/material'
import Snowfall from 'react-snowfall'
import { Widget } from '@typeform/embed-react'

function AppWrapper() {
    const currentYear = new Date().getFullYear()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <Container>
            <Snowfall
                color={
                    useTheme().palette.mode === 'dark' ? '#FFFFFF' : '#7FC8FF'
                }
                style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }}
            />
            <Container
                sx={{
                    justifyContent: 'space-around',
                    alignItems: 'initial',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Typography
                    sx={{ userSelect: 'none' }}
                    fontFamily={'greycliff-cf'}
                    color={
                        useTheme().palette.mode === 'dark'
                            ? '#FFFFFF'
                            : '#7FC8FF'
                    }
                >
                    <Typography variant={'h1'}>Minimaholic</Typography>
                    <Typography variant={'body1'}>
                        game studios. coming soon.{' '}
                        <Link onClick={handleOpen} underline={'hover'}>
                            Get on the wait list!
                        </Link>{' '}
                        ✨
                    </Typography>
                </Typography>
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80vw',
                            height: '80vh',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Widget
                            id="bnBH9zfy"
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </Modal>
            </Container>
            <Container disableGutters={true}>
                <Typography align={'center'} variant={'body2'}>
                    &copy; {currentYear - 1}-{currentYear}{' '}
                    <Link href="https://anirudhmergu.com" underline="none">
                        Anirudh Mergu
                    </Link>
                    . All Rights Reserved
                </Typography>
            </Container>
        </Container>
    )
}

export default AppWrapper
